@import './assets/styles/media';

body {
  @include md {
    overflow-y: hidden;
  }
}

.App {
  @include md {
    display: flex;
    flex-direction: column;
  }
}

main {
  min-height: 80vh;
  background-color: whitesmoke;
  flex-grow: 1;
  padding: 0.5em;

  @include sm {
    padding: 2.5em;
  }
}

* {
  font-family: 'Lato', sans-serif;
}

.ui.inverted.segment, .ui.primary.inverted.segment {
  background-color: #373a47 !important;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 25px;
  left: 36px;
  top: 23px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: whitesmoke;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: gray;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: whitesmoke;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  color: whitesmoke;
  display: inline-block;
  padding: 0.8em;
  font-size: 1.5rem;
  font-weight: bold;
}

a {
  &.bm-item {
    &:hover {
      color: gray;
    }
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
