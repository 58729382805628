.portfolio__nav {
  > div.ui.menu {
    border-radius: initial;
  }
  // border-right: 1px solid black;
  // text-align: center;

  // &__static {
  //   border-bottom: 1px solid black;
  //   &__image {
  //     width: 150px;
  //     height: auto;
  //     border-radius: 50%;
  //     padding: 2px;
  //     border: 6px solid whitesmoke;
  //     background-color: black;
  //   }
  // }

  // &__nav {
  //   display: flex;
  //   flex-direction: column;
  // }
}