@import '../../assets/styles/media';

.portfolio__work {
  &__items {
    &__item {
      &__headers {
        text-align: center;
        
        @include md {
          text-align: left;
        }
      }
      &__basic {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
      }
    }
  }

  &__highlights {
    list-style: none;
    margin: 0;
    padding: 0;

    &__highlight {
      padding: 5px 0;
    }
  }
}