.portfolio__about {

  &__bio {
    & p {
      font-size: 16px;
    }
  }

  &__contact__link {
    padding: 1em 0;
  }

  &__skills {
    &__skill {
      margin: .1em 0;
    }
  }
}