.portfolio__education {
  &__items {
    &__item{
      text-align: center;
      &__image {
        > img {
          max-width: 10em;
          height: auto;
        }
      }

      &__basic {
        display: flex;
        justify-content: space-evenly;
        font-weight: bold;
      }

      &__descripton {
        text-align: left;
        font-size: 14px;
      }
    }
  }
}